import { useQuery } from "@apollo/client";
import { useContext, useEffect, useRef, useState } from "react";
import imageQrcode from "src/assets/images/barcode-scan.png";
import { CartContext } from "src/context/CartContext";
import skuCSV from "src/data/sku.csv";
import { GET_PRODUCT } from "src/graphql/query/Product";
import { fetchAndParseCSV } from "src/helper/fetch_and_parse_csv";
import { PopUpFailComponent } from "./PopUpFailComponent";
import { PopUpProductComponentScan } from "./PopUpProductComponentScan";

export function ScanMenuComponent() {
  const refFail = useRef({ current: "block" });

  const { cart, setCart } = useContext(CartContext);

  const [idSku, setIdSku] = useState("");
  const [idProduct, setIdProduct] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  let temp = "";

  const handleKeyDown = (event) => {
    if (temp.length <= 6) {
      if (isUpperCaseLetter(event.key)) {
        temp = temp + event.key;
      }
    }
    if (event.key == "Enter") {
      fetchProduct(temp);
    }
  };

  const isUpperCaseLetter = (char) => {
    const code = /^[A-Z0-9]*$/.test(char);
    return code;
  };

  async function fetchProduct(tempText) {
    console.log(tempText);
    const idReal = tempText.substring(0, 6);
    console.log(idReal);
    const id = await getIDfromSKU(idReal);

    console.log(id);

    if (id == -1) {
      setIdProduct("");
      setErrorMsg("Product not found");
      refFail.current.show();
      return;
    } else {
      setIdProduct(id);
      setIdSku(temp);
      const res = await refetchProduct({
        productId: id,
      });
      temp = "";
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const {
    loading: loadingProduct,
    error: errorProduct,
    data: dataProduct,
    refetch: refetchProduct,
  } = useQuery(GET_PRODUCT, {
    variables: {
      productId: "",
    },
  });

  async function getIDfromSKU(idReal) {
    const skuArray = await fetchAndParseCSV(skuCSV);
    const index = skuArray.findIndex((item) => item[0] == idReal);
    if (index == -1) return -1;
    return skuArray[index][1];
  }

  function checkOnCart({ idFe }) {
    const index = cart.findIndex((item) => item.idForBuy == idFe);

    if (index == -1) return false;
    else return true;
  }

  return (
    <div className="w-full h-full grow">
      {idProduct != "" && dataProduct ? (
        <PopUpProductComponentScan
          product={dataProduct?.product}
          handleClose={() => {
            setIdProduct("");
            setIdSku("");
          }}
          callback={({ count, notes }) => {
            const idFe = idSku.substring(0, 9);
            if (!checkOnCart({ idFe: idFe }) || true) {
              const data = {
                ...dataProduct?.product,
                amount: 1,
                notes: notes,
                menuId: dataProduct?.product?.menuId,
                buy: true,
                idForBuy: idFe,
              };
              setCart([...cart, data]);
            } else {
              refFail.current.show();

              setTimeout(() => {
                refFail.current.close();
              }, 2000);
            }
            setIdProduct("");
            setIdSku("");
          }}
        />
      ) : null}

      <PopUpFailComponent myRef={refFail} message={errorMsg} />

      {/*<div className="w-full h-full bg-slate-100 rounded-3xl flex flex-col justify-center items-center">*/}
      {/*  <img src={imageQrcode} className="w-4/12" />*/}
      {/*  <h1 className="font-bold text-[48px] text-center mt-20">*/}
      {/*    Scan QR Code <br />*/}
      {/*    to Order*/}
      {/*  </h1>*/}
      {/*  <p className="f-h3 w-3/4 text-center mt-5">*/}
      {/*    Please make the card position at least 7 cm from the scanner (on the*/}
      {/*    bottom right)*/}
      {/*  </p>*/}
      {/*</div>*/}
    </div>
  );
}
